import { Subject } from 'rxjs';

type Position = {
  left: number;
  top: number;
};

type Size = {
  width: number;
  height: number;
};

type WindowOptions = Partial<Position> &
  Partial<Size> & {
    popup?: boolean;
  };

export default class WindowUtils {
  static getCenter(size?: Size): Position {
    let left = 0;
    let top = 0;

    const { width, height } = window.screen;
    const middleWidth = width / 2;
    const middleHeight = height / 2;

    left = middleWidth;
    top = middleHeight;

    if (size) {
      left = middleWidth - size.width / 2;
      top = middleHeight - size.height / 2;
    }

    return {
      left,
      top,
    };
  }

  static open(url: string, options: WindowOptions = {}) {
    const result$ = new Subject<void>();

    let windowFeatures = '';
    const { popup, height, width, left, top } = options;

    if (popup) {
      windowFeatures += 'popup=true,';
    }

    if (height) {
      windowFeatures += `height=${height},`;
    }

    if (width) {
      windowFeatures += `width=${width},`;
    }

    if (left) {
      windowFeatures += `left=${left},`;
    }

    if (top) {
      windowFeatures += `top=${top},`;
    }

    const win = window.open(url, '_blank', windowFeatures);

    if (!win) {
      throw new Error('Window was not found');
    }

    let timer = setInterval(() => {
      if (win.closed) {
        clearInterval(timer);
        result$.next();
        result$.complete();
      }
    }, 1000);

    return result$;
  }
}
